<template>
  <v-app-bar color="secondary" flat fixed>
    <v-row class="flex justify-md-center">
      <v-col
        cols="12"
        md="1"
        lg="1"
        xl="1"
        class="logo hidden-sm-and-down custom-font-fam"
      >
        <div class="font-weight-black primary--text">AFWF</div>
      </v-col>
      <v-col cols="12" md="7" lg="7" xl="7">
        <social-media class="float-end" />
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
import personalInfo from "@/assets/data/personalInfo.json";
export default {
  name: "CoreAppBar",

  components: {
    SocialMedia: () => import("@/components/SocialMedia"),
  },

  data: () => ({
    showLogo: false,
    isScrolling: false,
    name: `${personalInfo.firstname} ${personalInfo.lastname}`,
  }),

  methods: {
    onScroll() {
      const offset = window.pageYOffset;
      this.isScrolling = offset > 50;
      this.showLogo = offset > 200;
    },
  },
};
</script>

<style scoped>
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.w-full {
  width: 100%;
}
</style>
